import styled from 'styled-components'
import { theme } from '../../themes/anetwork'
import { layout, position, space, color } from 'styled-system'

export const GridContainer = styled.section`
  ${position}
`
export const Container = styled.div`
  ${layout}
  ${space}
  ${color}
`
export const ContentContainer = styled.div`
  ${layout}
  ${space}
  ${color}
  margin: 0 auto 15px;
`

export const H1 = styled.h1`
  ${color}
  font-family: ${theme.fonts.headings};
  font-size: 2.2rem;
  font-weight: 600;
  padding: 1.5em 0;
  line-height: 1.1;
  margin: 0;
`

export const H2 = styled.h2`
  ${color}
  font-family: ${theme.fonts.headings};
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1.8rem;
  margin-bottom: 2rem;
  line-height: 1.4;
  font-style: normal;
`

export const DoubleLine = styled.div`
  height: 3px;
  width: 100%;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin: 0;
`

export const P = styled.p`
  ${space}
  display: inline-block;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 1em;
  line-height: 2em;
  font-weight: 400;
  color: white;
  &:hover {
    color: ${({ active }) => active && '#ff691c'};
  }
`

export const Image = styled.img`
  ${layout};
  display: inline-block;
  height: auto;
  width: 100%;
  max-width: 564px;
`

export const ImageWrapper = styled.div`
  ${space}
`
export const StyledIconContainer = styled.div`
  display: inline;
  height: 3px;
  cursor: pointer;
`

export const StyledSocialContainer = styled.div`
  ${space}
  display: ${({ display }) => display};
`

export const StyledSvgWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translate(0px, -8px);
    cursor: pointer;
  }
`
