import React from 'react'
import PlayerDetail from '../components/PlayerDetail'
import MyColorGrid from '../components/MyColorGrid'

const Person = ({ name, theme, title, longBio, image, galleryItems }) => {
  return (
    <>
      <PlayerDetail
        name={name}
        theme={theme}
        image={image}
        title={title}
        longBio={longBio}
      />
      <MyColorGrid theme={theme} galleryItems={galleryItems} />
    </>
  )
}

export default Person
