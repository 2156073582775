import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import config from '../../config'
import TeamMember from '../../../common/templates/TeamMember'

const Person = ({ data, pageContext }) => {
  let { name, title, longBio, image } = data?.person.nodes[0]
  let { galleryItems, theme } = data?.gallery.nodes[0]

  let teamMembersExcludingCurrent = galleryItems.filter(
    item => item.id !== pageContext.id
  )

  return (
    <Layout {...pageContext} pageSEO config={config}>
      <TeamMember
        name={name}
        theme={theme}
        image={image}
        title={title}
        longBio={longBio && longBio.json}
        galleryItems={teamMembersExcludingCurrent}
      />
    </Layout>
  )
}

Person.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.exact({
    id: PropTypes.string,
  }).isRequired,
}

export default Person

export const query = graphql`
  query Person($id: String!) {
    person: allContentfulTeamMember(filter: { contentful_id: { eq: $id } }) {
      nodes {
        ...teamMemberFields
      }
    }
    gallery: allContentfulBladeGallery(
      filter: { contentful_id: { eq: "6xoIi5cNFNv45K2LVKOFvf" } }
    ) {
      nodes {
        ...bladeGalleryFields
      }
    }
  }
`
