import React from 'react'
import PropTypes from 'prop-types'
import { renderRichText } from './richText'
import {
  Container,
  ContentContainer,
  DoubleLine,
  H1,
  H2,
  Image,
  ImageWrapper,
} from './styles'
import './../../assets/fontello-font/css/fontello.css'

const PlayerDetail = ({ name, theme, image, title, longBio }) => {
  theme = theme ? 'light' : 'dark'

  let bio = longBio
    ? longBio.json
      ? renderRichText(longBio.json)
      : renderRichText(longBio)
    : ''

  return (
    <Container bg={`${theme}.bg`}>
      <ContentContainer px={[3, 4, 0]} width={['90%', '80.33%', null, '67%']}>
        <H1 color={`${theme}.text`}>{name}</H1>
        <ImageWrapper pr={[0, null, null, 3]}>
          <Image
            width={['100%', null, null, '60%']}
            src={image && image.fluid && image.fluid.src}
          />
        </ImageWrapper>
        <H2 color={`${theme}.text`}>{title}</H2>
        <hr />
        {bio}
      </ContentContainer>
      <DoubleLine />
    </Container>
  )
}

PlayerDetail.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  theme: PropTypes.bool.isRequired,
  longBio: PropTypes.exact({
    json: PropTypes.exact({
      data: PropTypes.object,
      content: PropTypes.arrayOf(PropTypes.object).isRequired,
      nodeType: PropTypes.string.isRequired,
    }),
  }),
  image: PropTypes.shape({
    id: PropTypes.string,
    fluid: PropTypes.object,
  }),
}

export default PlayerDetail
